import { useEffect, useRef } from 'react'

import useIsViewportVisible from './useIsViewportVisible'

// TODO: would be good to make this hook more generic, so it can handle any
// number of videos on any page
export const useAutoPlayVideos = () => {
  const bannerRef = useRef<HTMLVideoElement>(null)
  const thumbnailRef = useRef<HTMLVideoElement>(null)

  const isBannerVisible = useIsViewportVisible(bannerRef)
  const isThumbVisible = useIsViewportVisible(thumbnailRef)

  useEffect(() => {
    const startOrPauseVideos = async () => {
      if (bannerRef.current != null) {
        if (isBannerVisible) {
          await bannerRef.current.play()
        } else {
          bannerRef.current.pause()
        }
      }

      if (thumbnailRef.current != null) {
        if (isThumbVisible) {
          await thumbnailRef.current.play()
        } else {
          thumbnailRef.current.pause()
        }
      }
    }

    startOrPauseVideos()
  }, [isBannerVisible, isThumbVisible])

  return { bannerRef, thumbnailRef }
}

export default useAutoPlayVideos

import { useEffect, useState } from 'react'

const useIsViewportVisible = (ref: React.MutableRefObject<any>) => {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
      // Check if entries array is not empty before accessing the first entry
      if (entries && entries.length > 0) {
        setIntersecting(entries?.[0]?.isIntersecting || false)
      }
    })

    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [ref])

  return isIntersecting
}

export default useIsViewportVisible
